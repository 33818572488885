<template>
  <div class="collect">
    <van-nav-bar :title="$t('我的收藏')" left-arrow @click-left="onClickLeft" />
    <van-tabs v-model="active">
      <van-tab v-for="(item, index) in title" :key="index" :title="$t(item)">
        <div class="nape" v-for="(item, index) in list[index]" :key="index">
          <div class="img_box"><img :src="item.imgUrl" alt="" /></div>
          <div>
            <h6>{{ item.intangibleCulturalHeritageName || item.name }}</h6>
            <p>{{ item.briefIntroduction || item.introduce }}</p>
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
export default {
  name: "collect",
  data() {
    return {
      userId: null,
      active: 0,
      title: ["全部", "景区", "美食", "住宿", "非遗"],
      list: [
        //全部
        [],
        //景区
        [],
        //美食
        [],
        //住宿
        [],
        //非遗
        [],
      ],
    };
  },
  created() {
    this.userId = JSON.parse(sessionStorage.getItem("wxUser")) || null;
    if (this.userId == null) {
      console.log("请登录账号");
    } else {
      this.addList();
    }
  },
  methods: {
    onClickLeft() {
      this.$router.go("-1");
    },
    // 获取全部数据并渲染
    addList() {
      let params = new FormData();
      let abbreviation = JSON.parse(
        sessionStorage.getItem("abbreviation")
      ).travelAbbreviation;
      params.append("userId", this.userId.id);
      params.append("abbreviation", abbreviation);
      this.$axios.post("/api/msg/getUserCollectionList", params).then((res) => {
        // 处理全部数据
        res.data.list1.map((item) => {
          this.list[0].push(item);
        });
        res.data.list2.map((item) => {
          this.list[0].push(item);
          if (item.type == 1) {
            //  获取景点数据
            this.list[1].push(item);
          }
          if (item.type == 2) {
            //  获取美食数据
            this.list[2].push(item);
          }
          if (item.type == 3) {
            //  获取住宿数据
            this.list[3].push(item);
          }
        });
        this.list[4] = res.data.list1;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.collect {
  padding-top: 1.6rem;
  background: #f8f7f7;
}
/** 导航栏 */
/deep/ .van-nav-bar {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 2;
  i {
    font-size: 0.5rem;
    color: #000;
    margin-top: -0.01rem;
  }
  .van-nav-bar__title {
    font-weight: 500;
    font-size: 0.32rem;
    color: #000;
    margin-left: 0.9rem;
  }
}
/** 选项卡 */
/deep/ .van-tabs__wrap {
  width: 100%;
  height: 0.7rem;
  box-shadow: 0px 1px 4px 1px #e5e5e5;
  position: fixed;
  top: 0.94rem;
  z-index: 1;
  .van-tab {
    font-size: 0.28rem;
    color: #121212;
  }
  .van-tabs__line {
    width: 0.5rem;
    height: 0.06rem;
    border-radius: 0.06rem;
    bottom: 0.32rem;
    background: #249ede;
  }
}
/deep/ .van-tab__pane {
  height: 85vh;
  padding: 0.32rem;
  overflow: auto;
}
.nape {
  display: flex;
  align-items: center;
  height: 2rem;
  background: #fff;
  border-radius: 0.08rem;
  padding: 0.2rem;
  margin-bottom: 0.2rem;
  .img_box {
    margin-right: 0.32rem;
    width: 84%;
    height: 1.5rem;
    background: #fff;
    text-align: center;
    img {
      max-width: 2.84rem;
      max-height: 1.52rem;
    }
  }
  div {
    width: 100%;
    height: 100%;
    h6 {
      max-width: 3.4rem;
      font-size: 0.28rem;
      font-weight: 500;
      line-height: 0.5rem;
      color: #121212;
      // 单行文本溢出显示省略号
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    p {
      max-width: 3.4rem;
      font-size: 0.24rem;
      color: rgba(18, 18, 18, 0.5);
      text-indent: 2em;
      // 多行行文本溢出显示省略号
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }

  span {
    font-size: 0.16rem;
    color: rgba(18, 18, 18, 0.5);
  }
}
.nape:last-child {
  margin: 0;
}
</style>